import { cloneDeep } from "lodash-es";
import { type AskResponse, type Citation } from "~/api";

interface ParsedAnswer {
  citations: Citation[];
  markdownFormatText: string;
  callout: string;
}

export function parseAnswer(answer: AskResponse): ParsedAnswer {
  let answerText = answer.answer;
  const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = "[doc".length;

  const filteredCitations = [] as Citation[];
  let citationReindex = 0;
  citationLinks?.forEach((link) => {
    // Replacing the links/citations with number
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    const citation = cloneDeep(answer.citations[Number(citationIndex) - 1]);
    if (!filteredCitations.find((c) => c.id === citationIndex)) {
      answerText = answerText.replaceAll(link, ` ^${++citationReindex}^ `);
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
    }
  });

  let callout911 = "";
  const call911Regex = /[^.!?:*]*\b911\b[^.!?]*[.!?]/gi;
  const matches = answerText.match(call911Regex);
  if (matches) {
    callout911 = matches[0];
  }

  const regex = /\((https?:\/\/[^\s)]+)\)/g;
  answerText = answerText.replace(regex, '(\/bounce?url=$1)'); // add bounce to external links
  answerText = answerText.replace(/] +\(/g, ']('); // remove space added by translation between ] and (

  return {
    callout: callout911,
    citations: filteredCitations,
    markdownFormatText: answerText,
  };
}
