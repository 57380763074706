import cx from "classnames";
import { Card } from "semantic-ui-react";

import styles from "./InfoCard.module.css";
import "semantic-ui-css/semantic.min.css";
import "./InfoCard.css";

export interface IInfoCard {
  title: string;
  icon?: string;
  details: Detail[];
  onSendQuestion?: (question: string) => Promise<void>;
  isFirstCard?: boolean;
}

interface Detail {
  info: string | JSX.Element;
  icon?: string;
  emitQuestion?: boolean;
  detailRef?: any;
}

interface InfoCardDetailProps {
  detail: Detail;
}

const InfoCardDetail = ({ detail }: InfoCardDetailProps): JSX.Element => (
  <>
    <div ref={detail.detailRef}>
      <span className={styles.description}>{detail.info}</span>
    </div>
    {detail.icon && (
      <div>
        <img className={styles.descriptionImage} src={detail.icon} alt="" />
      </div>)
    }
  </>
);

export const InfoCard = ({
  title,
  icon,
  details,
  onSendQuestion,
  isFirstCard,
}: IInfoCard): JSX.Element => {
  const handleDetailClick = async (detail: Detail): Promise<void> => {
    // eslint-disable-next-line no-empty
    if (typeof onSendQuestion !== "function" || !detail.emitQuestion) {
    } else if (detail.detailRef?.current) {
      await onSendQuestion(detail.detailRef.current.textContent);
    }
  };

  return (
    <Card
      className={styles.cardContainer}
      style={{ marginTop: "unset", height: "100%" }}
    >
      <Card.Content>
        <Card.Header
          className={cx(styles.cardHeader, {
            [styles.firstCardHeader]: isFirstCard,
          })}
        >
          <div className={cx(styles.cardHeaderIcon, {
            [styles.firstCardHeaderIcon]: isFirstCard,
          })}>
            {icon && <img src={icon} alt="" />}
          </div>
          <h2 className={styles.cardHeaderTitle}>{title}</h2>
        </Card.Header>
        <Card.Description as="ul">
          {details.map((detail, index) =>
            isFirstCard ? (
              <li>
                <button
                  className={cx(styles.cardDescription, styles.firstCardDetails)}
                  key={index}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () => {
                    await handleDetailClick(detail);
                  }}
                  role="button"
                >
                  <InfoCardDetail detail={detail} />
                </button>
              </li>
            ) : (
              <li
                className={styles.cardDescription}
                key={index}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  await handleDetailClick(detail);
                }}
              >
                <InfoCardDetail detail={detail} />
              </li>
            ),
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default InfoCard;
