import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import cx from "classnames";

import { SquareRegular } from "@fluentui/react-icons";
import { Stack } from "@fluentui/react";

import { sendSubmitQuestionGa4CustomEvent } from "~/analytics";
import {
  type ChatMessage,
  type ConversationRequest,
  conversationApi,
  type Citation,
  type ChatResponse,
} from "~/api";
import {
  Answer,
  AiAvatarIcon,
  AuthMessage,
  Hyperlink,
  InfoCardList,
  QuestionInput,
  UserAvatarIcon,
  Header,
} from "~/components";
import {FEEDBACK_FORM_URL, NYC_POLICY_URL, NYC_TERMS_URL} from "~/config/config";
import { globalContext } from "~/context";
import { COND_RENDERED_TEXT_KEY, useShowAuthMessage } from "~/hooks";
import { debounce } from "~/utils";
import { parseCitationFromMessage } from "./Chat.utils";
import { SessionStorage } from "./SessionStorage";
import config from "~/config/config";

import Conversation from "~/assets/Conversation.svg";
import ArrowDownward from "~/assets/ArrowDownward.svg";
import styles from "./Chat.module.css";
import CaretUp from "~/assets/CaretUp.svg";
import CaretDown from "~/assets/CaretDown.svg";

const sessionStorage = new SessionStorage();

const Chat = (): JSX.Element => {
  const abortFuncs = useRef([] as AbortController[]);
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const chatStreamContainerRef = useRef<HTMLDivElement | null>(null);
  const chatInputRef = useRef<HTMLDivElement | null>(null);
  const emptyStateRef = useRef<HTMLDivElement | null>(null);

  const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const [chatId, setChatId] = useState<string>(uuidv4());
  const [chatInputHeight, setChatInputHeight] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);

  const currentYear = new Date().getFullYear();

  const { conditionallyRenderedText, setShouldDisplayHeader } =
    useContext(globalContext);
  const showAuthMessage = useShowAuthMessage();

  const makeApiRequest = async (
    question: string,
    recaptchaToken: string,
  ): Promise<void> => {
    setCurrentQuestion(question);
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      role: "user",
      content: question,
    };

    const request: ConversationRequest = {
      messages: [
        ...answers.filter((answer) => answer.role !== "error"),
        userMessage,
      ],
      sessionUUID: sessionStorage.sessionUUID,
      chatUUID: chatId,
      ...(recaptchaToken ? { recaptchaToken } : {}),
    };

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    let result = {} as ChatResponse;

    try {
      sendSubmitQuestionGa4CustomEvent(
        sessionStorage.sessionUUID,
        chatId,
        answers.filter((a) => a.role === "user").length + 1,
      );
      const response = await conversationApi(request, abortController.signal);
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach((obj) => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              setShowLoadingMessage(false);

              const assistantMessageIndex =
                result.choices[0].messages.findIndex(
                  (message) => message.role === "assistant",
                );
              if (assistantMessageIndex !== -1) {
                result.choices[0].messages[assistantMessageIndex].is_last =
                  result.is_last;
              }
              setAnswers([
                ...answers,
                userMessage,
                ...result.choices[0].messages,
              ]);
              runningText = "";
            } catch {}
          });
        }
        setAnswers([...answers, userMessage, ...result.choices[0].messages]);
        setAnswerIndex(answerIndex + 1);
      }
    } catch (e) {
      console.error("Chat::makeApiRequest::error\n", e);
      if (!abortController.signal.aborted) {
        let errorMessage =
          "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === "string") {
          errorMessage = result.error;
        }
        setAnswers([
          ...answers,
          userMessage,
          {
            role: "error",
            content: errorMessage,
            is_last: result.is_last,
          },
        ]);
      } else {
        setAnswers([...answers, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController,
      );
    }

    abortController.abort();
  };

  const handleCollapse = (event: any, submittedFeedback = true): void => {
    setShowDisclaimer(!showDisclaimer);
    console.log("Clicked "+ showDisclaimer);
  };

  const sendQuestion = async (question: string): Promise<void> => {
    try {
      const token = await window.grecaptcha.execute(window.siteKey, {
        action: "submit",
      });

      const gRecaptchaResponse = document.getElementsByName(
        "g-recaptcha-response",
      )[0] as HTMLInputElement;
      gRecaptchaResponse.value = token;

      // Now that you have the token, proceed with the API request
      await makeApiRequest(question, token ?? "");
    } catch (e) {
      console.log(e);
    }
  };

  const clearChat = useCallback((): void => {
    setAnswers([]);
    setChatId(uuidv4());
    setCurrentQuestion("");
    setPrevScrollPos(0);
    setShouldDisplayHeader(true);
  }, [
    setAnswers,
    setChatId,
    setCurrentQuestion,
    setPrevScrollPos,
    setShouldDisplayHeader,
  ]);

  const handleEmptyChatScroll = useCallback(() => {
    if (emptyStateRef.current) {
      emptyStateRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const stopGenerating = (): void => {
    (abortFuncs?.current ?? []).forEach((a) => {
      a?.abort?.();
    });
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    // https://react.dev/learn/referencing-values-with-refs#best-practices-for-refs
    const height = chatInputRef?.current?.offsetHeight;
    if (typeof height === "number" && height > 0) {
      setChatInputHeight(height);
    }
  }, [chatInputRef?.current?.offsetHeight]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      scrollToEnd();
    });

    if (chatStreamContainerRef?.current) {
      observer.observe(chatStreamContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [chatStreamContainerRef?.current]);

  const handleScroll = debounce(() => {
    const parentElement = chatStreamContainerRef.current;
    const childElement = chatMessageStreamEnd.current;

    if (parentElement && childElement) {
      const currentScrollPos = parentElement.scrollTop;
      const parentRect = parentElement.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();
      const endOfContent =
        Math.trunc(childRect.bottom - 40) > Math.trunc(parentRect.bottom);
      setShowScrollButton(endOfContent);

      !endOfContent
        ? setShouldDisplayHeader(false)
        : setShouldDisplayHeader(currentScrollPos < prevScrollPos);

      setPrevScrollPos(currentScrollPos);
    }
  }, 200);

  const scrollToEnd = useCallback(() => {
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessageStreamEnd]);

  const openCitation = useCallback((citation: Citation): void => {
    window.open(citation.url ?? "", "_blank")?.focus();
  }, []);

  if (showAuthMessage) return <AuthMessage />;

  return (
    <div className={styles.container} role="main">
      {!currentQuestion &&  <Header />}

      <Stack horizontal className={styles.chatRoot}>
        <div
          
          className={styles.chatContainer}
          style={{
            height: `calc(100vh - ${chatInputHeight}px`,
          }}
        >
          {!currentQuestion ? (
            <Stack
              className={styles.chatEmptyState}
              style={{
                ...(chatInputHeight > 0
                  ? { paddingBottom: chatInputHeight }
                  : {}),
              }}
            >
              <h1 className={styles.chatTitle}>
                MyCity Chatbot
                <span className={styles.superscriptTitle}> Beta</span>
              </h1>
              <p className={styles.chatEmptyStateIntro}>
                We are continuously working to improve the MyCity Chatbot, which uses information
                from various New York City Agencies and AI to answer your questions. Your <a href={config.FEEDBACK_FORM_URL} target="_blank" className={styles.feedback}> feedback</a>{" "}is invaluable for refinement.

              </p>
              <InfoCardList onQuestionReceived={sendQuestion} handleFocus={handleEmptyChatScroll} />
              <div className={styles.footerContainer}>
                <div className={styles.copyright}>
                  <span>
                    &copy; {currentYear} City of New York. All Rights Reserved.
                  </span>
                </div>
                <div className={styles.links}>
                  <Hyperlink onFocus={handleEmptyChatScroll} href={NYC_TERMS_URL} isTargetBlank>
                    Terms of Use
                  </Hyperlink>
                  <Hyperlink onFocus={handleEmptyChatScroll} href={NYC_POLICY_URL} isTargetBlank>
                    Privacy Policy
                  </Hyperlink>
                </div>
              </div>
              <div className={styles.emptyStateEnd} aria-hidden="true" ref={emptyStateRef}></div>
            </Stack>
          ) : (
            <div
              ref={chatStreamContainerRef}
              className={cx("notranslate", styles.chatMessageStream)}
              role="log"
              onScroll={handleScroll}
            >
              <Header />
              <div
                className={styles.chatMessageWrapperContainer}
                style={{
                  ...(chatInputHeight > 0
                    ? { paddingBottom: chatInputHeight }
                    : {}),
                }}
              >
                {/* eslint-disable-next-line array-callback-return */}
                {answers.map((answer, index) => {
                  switch (answer.role) {
                    case "user": {
                      return (
                        <div className={styles.chatMessageWrapper}>
                          <UserAvatarIcon />
                          <div className={styles.chatMessageUser} tabIndex={0}>
                            <div className={styles.chatMessageUserMessage}>
                              {answer.content}
                            </div>
                          </div>
                        </div>
                      );
                    }

                    case "assistant": {
                      return (
                        <div className={styles.chatMessageWrapper}>
                          <AiAvatarIcon />
                          <Answer
                            answer={{
                              answer: answer.content,
                              citations: parseCitationFromMessage(
                                answers[index - 1],
                              ),
                            }}
                            chatUUID={chatId}
                            answerIndex={answerIndex}
                            isStreamComplete={answer.is_last}
                            onCitationClicked={openCitation}
                            referenceText={
                              conditionallyRenderedText[
                                COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCE
                              ].ref?.current?.textContent ??
                              conditionallyRenderedText[
                                COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCE
                              ].text
                            }
                            referencesText={
                              conditionallyRenderedText[
                                COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCES
                              ].ref?.current?.textContent ??
                              conditionallyRenderedText[
                                COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCES
                              ].text
                            }
                            sessionUUID={sessionStorage.sessionUUID}
                          />
                          <div />
                        </div>
                      );
                    }

                    case "error": {
                      return (
                        <div className={styles.chatMessageError}>
                          <span className={styles.chatMessageErrorContent}>
                            {answer.content}
                          </span>
                        </div>
                      );
                    }
                  }
                })}
                {showLoadingMessage && (
                  <div className={styles.chatMessageStreamLoading} role="log">
                    <div className={styles.chatMessageWrapper}>
                      <UserAvatarIcon />
                      <div className={styles.chatMessageUser} tabIndex={0}>
                        <div className={styles.chatMessageUserMessage}>
                          {currentQuestion}
                        </div>
                      </div>
                    </div>
                    <div className={styles.chatMessageWrapper}>
                      <AiAvatarIcon />
                      <Answer
                        answer={{
                          answer:
                            conditionallyRenderedText[
                              COND_RENDERED_TEXT_KEY.GENERATING_ANSWER
                            ].ref?.current?.textContent ??
                            conditionallyRenderedText[
                              COND_RENDERED_TEXT_KEY.GENERATING_ANSWER
                            ].text,
                          citations: [],
                        }}
                        chatUUID={chatId}
                        answerIndex={answerIndex}
                        isLoading={isLoading}
                        onCitationClicked={() => null}
                        sessionUUID={sessionStorage.sessionUUID}
                      />
                    </div>
                  </div>
                )}
                <div ref={chatMessageStreamEnd} />
              </div>
            </div>
          )}

          <div className={styles.chatInput} ref={chatInputRef}>
            {showScrollButton && !isLoading && !!currentQuestion && (
                <div className={styles.scrollEnd} onClick={scrollToEnd}>
                  <img
                      src={ArrowDownward}
                      aria-label="scroll to end"
                      alt="Arrow Downward"
                  />
                </div>
            )}
            {isLoading && (
                <Stack
                    horizontal
                    className={styles.stopGeneratingContainer}
                    role="button"
                    aria-label="Stop generating"
                    tabIndex={0}
                    onClick={stopGenerating}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") stopGenerating();
                    }}
                >
                  <SquareRegular
                      className={styles.stopGeneratingIcon}
                      aria-hidden="true"
                  />
                  <span className={styles.stopGeneratingText} aria-hidden="true">
                  {conditionallyRenderedText[
                          COND_RENDERED_TEXT_KEY.STOP_GENERATION
                          ].ref?.current?.textContent ??
                      conditionallyRenderedText[
                          COND_RENDERED_TEXT_KEY.STOP_GENERATION
                          ].text}
                </span>
                </Stack>
            )}
            {!!currentQuestion && !isLoading && (
                <button
                    role="button"
                    className={styles.newConversation}
                    onClick={clearChat}
                >
                  <img
                      src={Conversation}
                      className={styles.newMessageIcon}
                      aria-hidden="true"
                      alt="Conversation"
                  />
                  <span>
                  {conditionallyRenderedText[COND_RENDERED_TEXT_KEY.NEW_CHAT]
                          .ref?.current?.textContent ??
                      conditionallyRenderedText[COND_RENDERED_TEXT_KEY.NEW_CHAT]
                          .text}
                </span>
                </button>
            )}
            <QuestionInput
                isLoading={isLoading}
                onSend={sendQuestion}
                placeholder="Ask an NYC government question only"
                shouldClearOnSend
            />
            <div className = {styles.disclaimerCaretContainer}>
              <button
                  className={styles.disclaimerExpand}
                  name="Disclaimer  Toggle"
                  aria-expanded={showDisclaimer}
                  onClick={(event) => {
                    handleCollapse(event, false);
                  }}
                  role="button"
              >
                <img src={showDisclaimer ? CaretDown : CaretUp} alt="Disclaimer"/>
              </button>
            </div>

            <div className={showDisclaimer ? styles.disclaimerContainer : styles.disclaimerContainerCollapsed}>
              IMPORTANT: Responses may occasionally produce inaccurate or incomplete content. <br/>
              Verify answers with the MyCity Chatbot's provided references after the response or on <a
                href={config.businessPage} target="_blank">MyCity Business</a> and <a
                href={config.NYC_311_PAGE} target="_blank">NYC 311</a>.
            </div>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default Chat;
